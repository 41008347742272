import React, { ReactNode } from 'react';
import NextErrorComponent from 'next/error';
import { NextPageContext } from 'next';
import { captureException } from '@sentry/nextjs';

export const PATH = '/_error';

type ErrorComponentProps = {
  statusCode?: number;
  err?: Error | null;
};
const Error = ({ statusCode, err }: ErrorComponentProps): ReactNode => {
  if (err && process.env.NEXT_PUBLIC_NSK_ENV !== 'development') {
    captureException(err);
  }
  return <NextErrorComponent statusCode={statusCode ?? 500} />;
};
Error.getInitialProps = ({
  res,
  err
}: NextPageContext): ErrorComponentProps => {
  const statusCode = ((): number => {
    if (res) {
      return res.statusCode;
    }
    return err?.statusCode ?? 404;
  })();
  return { statusCode, err };
};

export default Error;
